import { z } from 'zod'

export const defaultResponse = z.object({
	success: z.boolean(),
})
export const defaultError = defaultResponse.extend({
	success: z.literal(false),
	data: z.string(),
	code: z.string().optional(),
})

export const defaultErrorResponses = {
	400: defaultError.describe('Bad Request'),
	401: defaultError.describe('Unauthorized'),
	403: defaultError.describe('Forbidden'),
	404: defaultError.describe('Not Found'),
	500: defaultError.describe('Internal Server Error'),
}
