import { z } from 'zod'
import { companyNanoid, teamNanoid, userNanoid } from '../../brands.js'
import { selectable_rise_entities } from '../../codegen/zod/rise/rise_entities.js'
import { recaptcha } from '../../formats.js'
import { defaultErrorResponses, defaultResponse } from '../../response.js'
import type { Schema } from '../../types.js'

const relationshipTypes = selectable_rise_entities.shape.type.exclude([
	'company',
	'team',
	'payee',
	'payer',
	'viewer',
	'user',
])

export const schema = {
	'/dashboard/relationships': {
		get: {
			tags: ['Dashboard'],
			security: [{ bearerAuth: [] }],
			summary: 'List relationships',
			response: {
				200: defaultResponse.extend({
					data: z.array(
						z.object({
							relationship_nanoid: z.string(),
							entity_nanoid: z.string(),
							type: selectable_rise_entities.shape.type,
							avatar: z.string(),
							name: z.string(),
							parent: z.object({
								nanoid: z.string(),
								name: z.string(),
								type: selectable_rise_entities.shape.type,
								avatar: z.string(),
							}),
						}),
					),
				}),
				...defaultErrorResponses,
			},
		},
		delete: {
			tags: ['Dashboard'],
			security: [{ bearerAuth: [] }],
			summary: 'Delete relationships from companies or teams',
			headers: recaptcha,
			body: z.object({
				nanoids: z.array(companyNanoid.or(teamNanoid)),
			}),
			response: {
				200: defaultResponse,
				...defaultErrorResponses,
			},
		},
		post: {
			tags: ['Dashboard'],
			security: [{ bearerAuth: [] }],
			headers: recaptcha,
			summary: 'Create relationships with companies or teams',
			body: z.object({
				user_nanoid: userNanoid,
				relationships: z
					.object({
						entity_nanoid: companyNanoid.or(teamNanoid),
						type: relationshipTypes,
					})
					.array(),
			}),
			response: {
				200: defaultResponse,
				...defaultErrorResponses,
			},
		},
	},
} as const satisfies Schema
