import { useCookies } from "@vueuse/integrations/useCookies";

export default defineNuxtRouteMiddleware(async (to) => {
  if (to.query.oauth) return;

  const sessionStore = useSessionStore();

  if (to.name === "sign-out") {
    await sessionStore.signOut();
    return navigateTo({ path: "/sign-in" });
  }

  if (to.meta.layout === "authless") return;

  const clerkUserStore = useClerkUserStore();

  const userCookie = useCookies([]);
  const userCookieValue = userCookie.get("rise-user");
  if (userCookieValue) {
    clerkUserStore.user = userCookieValue;
  }
  const serverHasSession = userCookieValue || sessionStore.hasSession();

  if (!serverHasSession) {
    return navigateTo({ path: "/sign-in", query: { redirect: to.path } });
  }

  if (["sign-in", "sign-up"].includes(String(to.name)) && serverHasSession) {
    return navigateTo("/");
  }
  sessionStore.cleared = false;
});
