import { defineStore } from "pinia";

import { z } from "zod";
import { useLocalStorage } from "@vueuse/core";

import { schema } from "~/.nuxt/contracts/src/routes/dashboard/relationships";

export const getRelationshipsSchema =
  schema["/dashboard/relationships"].get.response[200].shape.data;

export type Relationship = z.infer<typeof getRelationshipsSchema.element>;
type Workspace = Relationship & { teams: Relationship[] };

export const postWorkspacesSchema =
  schema["/dashboard/relationships"].post.body;
export const deleteRelationshipsSchema =
  schema["/dashboard/relationships"].delete.body;

export type Role = z.infer<typeof getRelationshipsSchema.element.shape.type>;
export type Scope =
  | "organizations"
  | "teams"
  | "contractors"
  | "employees"
  | "unknown";

export type PostRelationship = z.infer<
  typeof postWorkspacesSchema.shape.relationships.element
>;

export const useRelationshipsStore = defineStore("relationships", {
  state: (): {
    relationships: Ref<z.infer<typeof getRelationshipsSchema>>;
    relationshipsIncreased: boolean;
  } => ({
    relationships: useLocalStorage("pinia/relationships/relationships", []),
    relationshipsIncreased: false,
  }),
  getters: {
    workspaces(state): Workspace[] & {} {
      const organizations = new Set();
      state.relationships.forEach((e) => {
        if (e.parent?.type === "company") {
          organizations.add(e.parent?.nanoid);
        }
      });
      const workspaces = [...organizations].map((companyNanoid) => {
        const workspace = state.relationships.find(
          (e) =>
            e.parent?.nanoid === companyNanoid && e.parent?.type === "company"
        );
        const companyParent = workspace?.parent;
        const teams = state.relationships.filter(
          (e) =>
            e.type &&
            ["teams", "contractors", "employees"].includes(
              this.roleScopes[e.type]
            ) &&
            e.parent.nanoid === companyParent?.nanoid
        );

        const orgRoleRelationship = state.relationships.find(
          (e) => this.roleScopes[e.type] === "organizations"
        );
        if (orgRoleRelationship) {
          return {
            ...orgRoleRelationship,
            teams,
          };
        }

        return {
          type: undefined,
          name: companyParent?.name,
          teams,
        };
      });

      console.log({ workspaces });

      return workspaces as Workspace[];
    },
    currentWorkspace(): Relationship | null {
      const workspaceId = this.$route?.params?.workspaceId;
      if (!workspaceId) return null;
      let currentWorkspace = null;
      this.workspaces.forEach((workspace) => {
        if (workspace.entity_nanoid === workspaceId) {
          currentWorkspace = workspace;
        }
        if (!workspace.teams) return;
        workspace.teams.forEach((teamWorkspace) => {
          if (teamWorkspace.entity_nanoid === workspaceId) {
            currentWorkspace = teamWorkspace;
          }
        });
      });
      return currentWorkspace;
    },
    currentOrganization(): Relationship | null {
      const workspaceId = this.$route.params.workspaceId;
      if (!workspaceId) return null;
      let currentOrganization = null;
      this.workspaces.forEach((workspace) => {
        if (workspace.entity_nanoid === workspaceId) {
          currentOrganization = workspace;
        }
        if (!workspace.teams) return;
        workspace.teams.forEach((teamWorkspace) => {
          if (teamWorkspace.entity_nanoid === workspaceId) {
            currentOrganization = workspace;
          }
        });
      });
      return currentOrganization;
    },
    currentScope(): Scope {
      if (!this.currentWorkspace?.type) return "unknown";
      return this.roleScopes[this.currentWorkspace.type];
    },
    adminInviteSelectRoles(): { label: string; value: Role }[] {
      const keys = Object.keys(this.roleMap) as Role[];
      return keys
        .filter(
          (e) =>
            (e.includes("team_") || e.includes("org_")) && e !== "team_employee"
        )
        .map((e) => {
          return {
            label: this.roleMap[e],
            value: e,
          };
        });
    },
    teamInviteSelectRoles(): { label: string; value: Role }[] {
      const keys = Object.keys(this.roleMap) as Role[];
      return keys
        .filter((e) => e.includes("team_") || e.includes("contractor"))
        .map((e) => {
          return {
            label: this.roleMap[e],
            value: e,
          };
        });
    },
    roleMap(): Record<Role, string> {
      return {
        org_admin: "Organization Admin",
        org_finance_admin: "Organization Finance Admin",
        org_viewer: "Organization Viewer",
        team_admin: "Team Admin",
        team_finance_admin: "Team Finance Admin",
        team_viewer: "Team Viewer",
        contractor: "Contractor",
        team_employee: "Employee",
        company: "Owner",
        payee: "unknown",
        payer: "unknown",
        team: "unknown",
        user: "Account",
        viewer: "unknown",
      };
    },
    roleScopes(): Record<Role, Scope> {
      return {
        org_admin: "organizations",
        org_finance_admin: "organizations",
        org_viewer: "organizations",
        team_admin: "teams",
        team_finance_admin: "teams",
        team_viewer: "teams",
        contractor: "contractors",
        team_employee: "employees",
        company: "organizations",
        payee: "unknown",
        payer: "unknown",
        team: "unknown",
        user: "unknown",
        viewer: "unknown",
      };
    },
    teamsSelectList(): { label: string; value: string }[] {
      if (!this.currentWorkspace) return [];
      const teams = (this.currentWorkspace as Workspace).teams;
      if (!teams) return [];
      return teams.map((team: Relationship) => {
        return {
          label: team.name as string,
          value: team.entity_nanoid as string,
        };
      });
    },
  },
  actions: {
    async getRelationships(payload?: { checkLeftMenuPing?: boolean }) {
      const response = await this.$client["/dashboard/relationships"].get();
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      const responseJson = await response.json();
      if (
        payload?.checkLeftMenuPing &&
        responseJson.data.length > this.relationships.length
      ) {
        this.relationshipsIncreased = true;
      }
      this.relationships = getRelationshipsSchema.parse(responseJson.data);
    },
    async createRelationships(
      relationships: PostRelationship[],
      userNanoid: string
    ) {
      const response = await this.$client["/dashboard/relationships"].post({
        json: {
          user_nanoid: userNanoid,
          relationships,
        },
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
    },
    async deleteRelationships(nanoids: string[]) {
      const response = await this.$client["/dashboard/relationships"].delete({
        json: {
          nanoids,
        },
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
    },
    isOrganization(role?: Role) {
      if (!role) return "";
      return ["org_admin", "org_finance_admin", "org_viewer"].includes(role);
    },
    scopeName(scope: Scope): string {
      const scopeNames: Record<Scope, string> = {
        organizations: "Organization",
        teams: "Team",
        employees: "Employee",
        contractors: "Contractor",
        unknown: "Unknown",
      };
      return scopeNames[scope];
    },
  },
});
