import { z } from 'zod'

export const rise_entities = z.object({
	riseid: z.string().trim().min(1),
	type: z.enum(['org_admin','org_finance_admin','org_viewer','team_admin','team_viewer','team_finance_admin','contractor','team_employee','payee','payer','viewer','user','company','team']),
	parent_riseid: z.string().trim().min(1),
	wallet: z.string().trim().nullish(),
	nanoid: z.string().trim().min(1),
	avatar: z.string().trim().optional().default(''),
	last_modified_by: z.string().trim().nullish(),
	created_at: z.union([z.number(), z.string(), z.date()]).pipe(z.coerce.date()).optional(),
	updated_at: z.union([z.number(), z.string(), z.date()]).pipe(z.coerce.date()).optional(),
})

export const insertable_rise_entities = z.object({
  riseid: z.string().trim().min(1),
  type: z.enum(['org_admin','org_finance_admin','org_viewer','team_admin','team_viewer','team_finance_admin','contractor','team_employee','payee','payer','viewer','user','company','team']),
  parent_riseid: z.string().trim().min(1),
  wallet: z.string().trim().nullish(),
  nanoid: z.string().trim().min(1),
  avatar: z.string().trim().optional().default(''),
  last_modified_by: z.string().trim().nullish(),
})

export const updateable_rise_entities = z.object({
  riseid: z.string().trim().min(1).optional(),
  type: z.enum(['org_admin','org_finance_admin','org_viewer','team_admin','team_viewer','team_finance_admin','contractor','team_employee','payee','payer','viewer','user','company','team']).optional(),
  parent_riseid: z.string().trim().min(1).optional(),
  wallet: z.string().trim().nullish(),
  nanoid: z.string().trim().min(1).optional(),
  avatar: z.string().trim().optional().default(''),
  last_modified_by: z.string().trim().nullish(),
})

export const selectable_rise_entities = z.object({
  riseid: z.string(),
  type: z.enum(['org_admin','org_finance_admin','org_viewer','team_admin','team_viewer','team_finance_admin','contractor','team_employee','payee','payer','viewer','user','company','team']),
  parent_riseid: z.string(),
  wallet: z.string().nullable(),
  nanoid: z.string(),
  avatar: z.string(),
  last_modified_by: z.string().nullable(),
  created_at: z.union([z.number(), z.string(), z.date()]).pipe(z.coerce.date()),
  updated_at: z.union([z.number(), z.string(), z.date()]).pipe(z.coerce.date()),
})

export type RiseEntitiesType = z.infer<typeof rise_entities>
export type InsertableRiseEntitiesType = z.infer<typeof insertable_rise_entities>
export type UpdateableRiseEntitiesType = z.infer<typeof updateable_rise_entities>
export type SelectableRiseEntitiesType = z.infer<typeof selectable_rise_entities>
