import Pusher from "pusher-js";

export default defineNuxtPlugin(async (nuxtApp) => {
  const runtimeConfig = useRuntimeConfig();
  const app = nuxtApp.vueApp;
  const pusher = new Pusher(runtimeConfig.public.pusherAppKey, {
    cluster: runtimeConfig.public.pusherCluster,
  });
  app.provide("pusher", pusher);
  app.config.globalProperties.$pusher = pusher;
});
