import { z } from 'zod'
import { companyNanoid, inviteNanoid, teamNanoid } from '../../brands.js'
import { invites } from '../../codegen/zod/rise/invites.js'
import { recaptcha } from '../../formats.js'
import { defaultErrorResponses, defaultResponse } from '../../response.js'
import type { Schema } from '../../types.js'

export const prefill = z.object({
	first_name: z.string().min(1),
	middle_name: z.string().optional(),
	last_name: z.string().min(1),
	dob: z.string().optional(),
	line_1: z.string().optional(),
	line_2: z.string().optional(),
	city: z.string().optional(),
	state: z.string().optional(),
	country: z.string().optional(),
	zip_code: z.string().optional(),
	timezone: z.string().optional(),
})

const postInvitesBody = z.object({
	anonymous: z.boolean(),
	role: invites.shape.role,
	emails: z.array(z.string().email().trim()).min(1).max(100),
	nanoid: companyNanoid.or(teamNanoid),
	type: z.enum(['company', 'team']),
})

const getInviteResponse = defaultResponse.extend({
	data: z.object({
		role: invites.shape.role,
		email: z.string(),
		team_name: z.string().nullable(),
		company_name: z.string().nullable(),
		inviter_name: z.string().min(1),
		prefill: prefill.partial(),
	}),
})

const postWarmInviteBody = z.object({
	anonymous: z.boolean(),
	role: invites.shape.role,
	nanoid: companyNanoid.or(teamNanoid),
	email: z.string().email(),
	type: z.enum(['company', 'team']),
	prefill,
})

const postBatchInvitesBody = z.object({
	anonymous: z.boolean(),
	role: invites.shape.role,
	nanoid: companyNanoid.or(teamNanoid),
	type: z.enum(['company', 'team']),
	invites: z
		.object({
			email: z.string().email(),
			first_name: z.string().min(1),
			middle_name: z.string(),
			last_name: z.string().min(1),
			dob: z.string(),
			line_1: z.string(),
			line_2: z.string(),
			city: z.string(),
			state: z.string(),
			country: z.string(),
			zip_code: z.string(),
			timezone: z.string(),
		})
		.array(),
})
export const schema = {
	'/dashboard/invites/:nanoid': {
		get: {
			tags: ['Dashboard'],
			security: [{ bearerAuth: [] }],
			summary: 'Get invite by nanoid',
			params: z.object({
				nanoid: inviteNanoid,
			}),
			querystring: z.object({
				secret: z.string().min(1),
			}),
			response: {
				200: getInviteResponse,
				...defaultErrorResponses,
			},
		},
	},
	'/dashboard/invites': {
		post: {
			tags: ['Dashboard'],
			security: [{ bearerAuth: [] }],
			summary: 'Create invites',
			headers: recaptcha,
			body: postInvitesBody,
			response: {
				200: defaultResponse.extend({
					data: z.object({
						nanoids: z.string().array(),
					}),
				}),
				...defaultErrorResponses,
			},
		},
	},
	'/dashboard/invites/warm': {
		post: {
			tags: ['Dashboard'],
			security: [{ bearerAuth: [] }],
			summary: 'Warm up invites',
			headers: recaptcha,
			body: postWarmInviteBody,
			response: {
				200: defaultResponse,
				...defaultErrorResponses,
			},
		},
	},
	'/dashboard/invites/batch': {
		post: {
			tags: ['Dashboard'],
			security: [{ bearerAuth: [] }],
			summary: 'Create invites in batch',
			headers: recaptcha,
			body: postBatchInvitesBody,
			response: {
				200: defaultResponse,
				...defaultErrorResponses,
			},
		},
	},
} as const satisfies Schema
