export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon/favicon.ico"},{"rel":"shortcut icon","type":"image/x-icon","href":"/favicon/favicon.ico"},{"rel":"apple-touch-icon","type":"image/x-icon","href":"/favicon/apple-icon-57x57.png","sizes":"57x57"},{"rel":"apple-touch-icon","type":"image/x-icon","href":"/favicon/apple-icon-60x60.png","sizes":"60x60"},{"rel":"apple-touch-icon","type":"image/x-icon","href":"/favicon/apple-icon-72x72.png","sizes":"72x72"},{"rel":"apple-touch-icon","type":"image/x-icon","href":"/favicon/apple-icon-76x76.png","sizes":"76x76"},{"rel":"apple-touch-icon","type":"image/x-icon","href":"/favicon/apple-icon-114x114.png","sizes":"114x114"},{"rel":"apple-touch-icon","type":"image/x-icon","href":"/favicon/apple-icon-120x120.png","sizes":"120x120"},{"rel":"apple-touch-icon","type":"image/x-icon","href":"/favicon/apple-icon-144x144.png","sizes":"144x144"},{"rel":"apple-touch-icon","type":"image/x-icon","href":"/favicon/apple-icon-152x152.png","sizes":"152x152"},{"rel":"apple-touch-icon","type":"image/x-icon","href":"/favicon/apple-icon-180x180.png","sizes":"180x180"},{"rel":"icon","type":"image/png","href":"/favicon/android-icon-192x192.png","sizes":"192x192"},{"rel":"icon","type":"image/png","href":"/favicon/android-icon-144x144.png","sizes":"144x144"},{"rel":"icon","type":"image/png","href":"/favicon/android-icon-96x96.png","sizes":"96x96"},{"rel":"icon","type":"image/png","href":"/favicon/android-icon-72x72.png","sizes":"72x72"},{"rel":"icon","type":"image/png","href":"/favicon/android-icon-36x36.png","sizes":"36x36"},{"rel":"icon","type":"image/png","href":"/favicon/android-icon-16x16.png","sizes":"16x16"}],"style":[],"script":[],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000