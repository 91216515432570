import { VueReCaptcha } from "vue-recaptcha-v3";

export default defineNuxtPlugin(async (nuxtApp) => {
  const runtimeConfig = useRuntimeConfig();
  const app = nuxtApp.vueApp;

  app.use(VueReCaptcha, {
    siteKey: runtimeConfig.public.GOOGLE_RECAPTCHA_KEY as string,
    loaderOptions: {
      autoHideBadge: true,
    },
  });

  const recaptcha = ref();

  function updateExecute() {
    recaptcha.value = app.config.globalProperties.$recaptcha;
  }

  app.provide("recaptcha", {
    recaptcha,
  });

  await app.config.globalProperties.$recaptchaLoaded();
  // app.config.globalProperties.$recaptchaInstance.hideBadge();

  updateExecute();
});
