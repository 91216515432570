import { defineStore } from "pinia";
import { useCookies } from "@vueuse/integrations/useCookies";
import type {
  CreateExternalAccountParams,
  EmailAddressResource,
  ExternalAccountResource,
  SetProfileImageParams,
  TOTPResource,
  UpdateUserParams,
  VerifyTOTPParams,
} from "@clerk/types";
import { type UserNanoid } from "~/.nuxt/contracts/src/brands";

type User = ExternalAccountResource & {
  primaryEmailAddress: EmailAddressResource;
  externalAccounts: ExternalAccountResource[];
  twoFactorEnabled: boolean;
  totpEnabled: boolean;
};

export type RiseOAuthProvider = "oauth_google" | "oauth_coinbase";

export const useClerkUserStore = defineStore("clerkUser", {
  state: (): {
    user: User | null;
    modalOpened: boolean;
    triggerPictureInput: boolean;
  } => ({
    user: null,
    modalOpened: false,
    triggerPictureInput: false,
  }),
  getters: {
    userNanoid(state) {
      if (!state.user) return "";

      return state.user.publicMetadata.user_nanoid as UserNanoid;
    },
    email(state) {
      return state.user?.primaryEmailAddress.emailAddress;
    },
    hasMFA(state) {
      return state.user?.twoFactorEnabled && state.user?.totpEnabled;
    },
    oAuthProviders(): Record<
      RiseOAuthProvider,
      { image: string; title: string }
    > {
      return {
        oauth_google: {
          image: "/icons/google.svg",
          title: "Google",
        },
        oauth_coinbase: {
          image: "/icons/coinbase-blue.svg",
          title: "Coinbase",
        },
      };
    },
    availableProviders() {
      return Object.keys(this.oAuthProviders) as RiseOAuthProvider[];
    },
  },
  actions: {
    setUser() {
      const userData = this.$clerk.user
        ? this.$clerk.user
        : this.$clerk.client?.sessions[0]?.user;
      if (!userData) return;

      this.user = userData;
      const userCookie = useCookies([]);
      userCookie.set("rise-user", userData);
    },
    async updateUser() {
      await this.$clerk.user.update<UpdateUserParams>({
        first_name: this.user?.firstName,
        last_name: this.user?.lastName,
      });
      this.setUser();
    },
    async setProfileImage(file: File | null) {
      await this.$clerk.user.setProfileImage<SetProfileImageParams>({ file });
      this.setUser();
    },
    async addConnectedAccount({
      strategy,
      redirectUrl,
    }: CreateExternalAccountParams) {
      const response =
        await this.$clerk.user.createExternalAccount<CreateExternalAccountParams>(
          {
            strategy,
            redirectUrl,
          }
        );

      if (!response) return;
      window.location.href =
        response.verification.externalVerificationRedirectURL.href;
    },
    async deleteConnectedAccount(account: ExternalAccountResource) {
      await account.destroy();
      this.setUser();
    },
    async createTOTP() {
      const response = await this.$clerk.user.createTOTP();

      return response as TOTPResource;
    },
    async verifyTOTP(code: string) {
      const response = await this.$clerk.user.verifyTOTP<VerifyTOTPParams>({
        code,
      });
      if (this.user) this.user.totpEnabled = true;
      if (this.user) this.user.twoFactorEnabled = true;

      return response as TOTPResource;
    },
    async disableTOTP() {
      const response = await this.$clerk.user.disableTOTP();
      if (this.user) this.user.totpEnabled = false;

      return response;
    },
  },
});
