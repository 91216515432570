import { defineStore } from "pinia";

import { z } from "zod";
import { useLocalStorage } from "@vueuse/core";
import { type Ref } from "vue";
import { schema } from "~/.nuxt/contracts/src/routes/onboarding/onboarding";

const getOnboardingResponseSchema =
  schema["/onboarding"].get.response[200].shape.data;

type OnboardingStep = z.infer<typeof getOnboardingResponseSchema.shape.step>;

const onboardingStepMap: Record<keyof OnboardingStep, string> = {
  details: "Details",
  business_details: "Business Details",
  business_owners: "Business Owners",
  business: "Business",
  compliance_collect: "Compliance Collect",
  compliance_process: "Compliance Processing",
  security: "Security",
  agreement: "Agreement",
  riseid: "RiseId",
  complete: "Complete",
  initial: "Initial",
  signer: "Signer",
  signing: "Signing",
};

export const useOnboardingStore = defineStore("onboarding", {
  state: (): {
    onboarding: Ref<z.infer<typeof getOnboardingResponseSchema>>;
  } => ({
    onboarding: useLocalStorage("pinia/onboarding/onboarding", {
      onboarded: false,
    }),
  }),
  getters: {
    onboardingStepMap(): Record<keyof OnboardingStep, string> {
      return onboardingStepMap;
    },
    onboardingStepOptions(): { label: string; value: OnboardingStep }[] {
      const keys = Object.keys(this.onboardingStepMap) as OnboardingStep[];

      return keys.map((e) => {
        return {
          label: this.onboardingStepMap[e as keyof OnboardingStep],
          value: e,
        };
      });
    },
  },
  actions: {
    async getOnboarding() {
      const response = await this.$client[`/onboarding`].get();
      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const responseJson = await response.json();
      this.onboarding = responseJson.data;
    },
  },
});
