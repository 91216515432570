import { default as developerfcqaIPwzXWMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/developer.vue?macro=true";
import { default as addressLZF6bOC3oPMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/[organizationId]/address.vue?macro=true";
import { default as contactsSqiBgDLpFUMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/[organizationId]/contacts.vue?macro=true";
import { default as detailsaW3CJgXG9oMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/[organizationId]/details.vue?macro=true";
import { default as legal94EulP81SJMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/[organizationId]/legal.vue?macro=true";
import { default as ownership4y3iql4KXRMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/[organizationId]/ownership.vue?macro=true";
import { default as settings3seLRAWcyXMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/[organizationId]/settings.vue?macro=true";
import { default as _91organizationId_93jv1rROofuKMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/[organizationId].vue?macro=true";
import { default as index1HrjK9ZiW1Meta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/index.vue?macro=true";
import { default as passkeypZ8XhKW1c7Meta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/passkey.vue?macro=true";
import { default as perkspJqzqUHPqRMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/perks.vue?macro=true";
import { default as address4wGbDW6de0Meta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/persona/address.vue?macro=true";
import { default as certificationsipDdc28f8SMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/persona/certifications.vue?macro=true";
import { default as profileK1gdF4QRBoMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/persona/profile.vue?macro=true";
import { default as socialopyccWUuI2Meta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/persona/social.vue?macro=true";
import { default as personaER107y7pMTMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/persona.vue?macro=true";
import { default as referral4liicupS3TMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/referral.vue?macro=true";
import { default as securityLZXcbfu7XrMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/security.vue?macro=true";
import { default as _91uuid_93phQ12yJvNIMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/auth/invite/[uuid].vue?macro=true";
import { default as _91token_93mmOAvQsjvqMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/auth/invite/b2b/[token].vue?macro=true";
import { default as arbitrum_45walletksbsJMceTJMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/arbitrum-wallet.vue?macro=true";
import { default as binance86IIh3dykRMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/binance.vue?macro=true";
import { default as coinbasexMkQHh1Mc4Meta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/coinbase.vue?macro=true";
import { default as domestic_45usdYYALaG02QIMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/domestic-usd.vue?macro=true";
import { default as ethereum_45walleted1vGnLcaxMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/ethereum-wallet.vue?macro=true";
import { default as forexGxhMtZR6VPMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/forex.vue?macro=true";
import { default as international_45usd_45manualZYcdXhjbGnMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/international-usd-manual.vue?macro=true";
import { default as international_45usdC79A8bXlL3Meta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/international-usd.vue?macro=true";
import { default as krakenLppXPeFRV4Meta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/kraken.vue?macro=true";
import { default as addHUKVlDreEiMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add.vue?macro=true";
import { default as indexN0esn6QQb9Meta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/index.vue?macro=true";
import { default as dashboardAABb68PYBEMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/dashboard.vue?macro=true";
import { default as documents5adKSMhaCfMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/documents.vue?macro=true";
import { default as completedFpdDf6aYioMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/history/completed.vue?macro=true";
import { default as pending7BIrFMWSwMMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/history/pending.vue?macro=true";
import { default as _91id_937VP7lhubc6Meta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/invoices/flat/[id].vue?macro=true";
import { default as indexvbwNk6jw47Meta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/invoices/flat/index.vue?macro=true";
import { default as indexieMuc7nhMTMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/invoices/index.vue?macro=true";
import { default as _91id_93vA6MlztUxSMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/invoices/time/[id].vue?macro=true";
import { default as indexG6ORpR0WsRMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/invoices/time/index.vue?macro=true";
import { default as tasksmtm2dRVUcRMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/tasks.vue?macro=true";
import { default as cryptoXhcb8XeAUuMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw/[accountId]/crypto.vue?macro=true";
import { default as fiat6W5jtewCcIMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw/[accountId]/fiat.vue?macro=true";
import { default as directhceFzbQpJWMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw/[nanoId]/direct.vue?macro=true";
import { default as withdrawgC3CrP8UWCMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw.vue?macro=true";
import { default as benefitsxjN7I2AHRTMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/benefits.vue?macro=true";
import { default as dashboardnJgqAeWwXeMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/dashboard.vue?macro=true";
import { default as documentsOMp9mptbrIMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/documents.vue?macro=true";
import { default as pay_45slipsGseuTkRGryMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/pay-slips.vue?macro=true";
import { default as reimbursementsyhXaQ8ICjfMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/reimbursements.vue?macro=true";
import { default as tasksS4VsYAtaibMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/tasks.vue?macro=true";
import { default as withdraw0f5WESbs29Meta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/withdraw.vue?macro=true";
import { default as indexq8ipwTDgX7Meta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/index.vue?macro=true";
import { default as _91nanoid_93FAAX2PTVj6Meta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/invites/[nanoid].vue?macro=true";
import { default as jwtCJnsO2KZjjMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/jwt.vue?macro=true";
import { default as activity19HOxGAs6GMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/activity.vue?macro=true";
import { default as administratorssfSLeX6bXRMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/administrators.vue?macro=true";
import { default as dashboardBfqx3cSjzkMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/dashboard.vue?macro=true";
import { default as completedgBVgeNzKC6Meta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/history/completed.vue?macro=true";
import { default as pendingkinbiIBTLYMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/history/pending.vue?macro=true";
import { default as directebLEBrSj6QMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites/direct.vue?macro=true";
import { default as warmKebzWr5cD4Meta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites/warm.vue?macro=true";
import { default as inviteszofoWiXFmAMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites.vue?macro=true";
import { default as subscriptionLMyGIDgIJuMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/subscription.vue?macro=true";
import { default as tasks95Uv3aOua2Meta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/tasks.vue?macro=true";
import { default as _91teamId_93LzICO9YmGqMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/teams/[teamId].vue?macro=true";
import { default as index5ZeVypeTlWMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/teams/index.vue?macro=true";
import { default as balance_45transferLBDFGtJVzNMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/balance-transfer.vue?macro=true";
import { default as blockchain_45addressODsD19CSKuMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit/blockchain-address.vue?macro=true";
import { default as wire0fl7OfUTjLMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit/wire.vue?macro=true";
import { default as depositlCeeBZg4LJMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit.vue?macro=true";
import { default as playgroundigxFUPOKj5Meta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/playground.vue?macro=true";
import { default as _91_46_46_46id_93HH00REp7NfMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/receipts/[...id].vue?macro=true";
import { default as externalpgA4ZUtqXhMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/receipts/external.vue?macro=true";
import { default as _2faXuROGkTjMvMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/2fa.vue?macro=true";
import { default as codebqWW7EFOlfMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/code.vue?macro=true";
import { default as index4hWSLz3uLBMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/index.vue?macro=true";
import { default as sign_45outDsrwIceWhoMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-out.vue?macro=true";
import { default as code0hPzc2VAwXMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/code.vue?macro=true";
import { default as indexbWvDOlI3H4Meta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/index.vue?macro=true";
import { default as waitingx1vf00Kra7Meta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/waiting.vue?macro=true";
import { default as signbABMp31caIMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign.vue?macro=true";
import { default as dashboardQvhq9ae6A1Meta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/dashboard.vue?macro=true";
import { default as blockchain_45address2G5q7U571JMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit/blockchain-address.vue?macro=true";
import { default as wireAIlIPX4M4KMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit/wire.vue?macro=true";
import { default as depositSbkq6VASvWMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit.vue?macro=true";
import { default as documents7xgB5ZdKSYMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/documents.vue?macro=true";
import { default as completeddnsMPX92dMMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/history/completed.vue?macro=true";
import { default as pendingxstgxfLmlrMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/history/pending.vue?macro=true";
import { default as historyNj4P1dv5K8Meta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/history.vue?macro=true";
import { default as batch4bxuJq3uiEMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/batch.vue?macro=true";
import { default as directM987HTCpS5Meta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/direct.vue?macro=true";
import { default as warmOofOCQWgFzMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/warm.vue?macro=true";
import { default as invitesiYdgLpgNcLMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites.vue?macro=true";
import { default as invoices9bIoeFRxjNMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invoices.vue?macro=true";
import { default as benefitsDTnBHCEhFsMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId]/benefits.vue?macro=true";
import { default as summaryrWLzPBQiS6Meta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId]/summary.vue?macro=true";
import { default as _91userId_937py7a50eilMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId].vue?macro=true";
import { default as indexdKP2JDDXb4Meta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/index.vue?macro=true";
import { default as csvXNBCy7sfQeMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/batch/csv.vue?macro=true";
import { default as indexiGVqodpOSDMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/batch/index.vue?macro=true";
import { default as indexkaXaHrG7tEMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/index.vue?macro=true";
import { default as directWDruCRFYIsMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/direct.vue?macro=true";
import { default as hourlyTkMbDwb6tqMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/hourly.vue?macro=true";
import { default as instantN4FWYowsnUMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/instant.vue?macro=true";
import { default as milestoneUskVuxbugMMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/milestone.vue?macro=true";
import { default as oncetOxqPCPMG1Meta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/once.vue?macro=true";
import { default as recurringW5aAuRJtSKMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/recurring.vue?macro=true";
import { default as _91userId_93nMgf62tODyMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId].vue?macro=true";
import { default as payrolltwK0LlTdAjMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll.vue?macro=true";
import { default as settingsyyqlUNYZTuMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/settings.vue?macro=true";
import { default as tasks7asV6fLXcnMeta } from "/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/tasks.vue?macro=true";
export default [
  {
    name: "account-developer",
    path: "/account/developer",
    meta: developerfcqaIPwzXWMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/developer.vue").then(m => m.default || m)
  },
  {
    name: "account-organizations-organizationId",
    path: "/account/organizations/:organizationId()",
    meta: _91organizationId_93jv1rROofuKMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/[organizationId].vue").then(m => m.default || m),
    children: [
  {
    name: "account-organizations-organizationId-address",
    path: "address",
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/[organizationId]/address.vue").then(m => m.default || m)
  },
  {
    name: "account-organizations-organizationId-contacts",
    path: "contacts",
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/[organizationId]/contacts.vue").then(m => m.default || m)
  },
  {
    name: "account-organizations-organizationId-details",
    path: "details",
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/[organizationId]/details.vue").then(m => m.default || m)
  },
  {
    name: "account-organizations-organizationId-legal",
    path: "legal",
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/[organizationId]/legal.vue").then(m => m.default || m)
  },
  {
    name: "account-organizations-organizationId-ownership",
    path: "ownership",
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/[organizationId]/ownership.vue").then(m => m.default || m)
  },
  {
    name: "account-organizations-organizationId-settings",
    path: "settings",
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/[organizationId]/settings.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "account-organizations",
    path: "/account/organizations",
    meta: index1HrjK9ZiW1Meta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/index.vue").then(m => m.default || m)
  },
  {
    name: "account-passkey",
    path: "/account/passkey",
    meta: passkeypZ8XhKW1c7Meta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/passkey.vue").then(m => m.default || m)
  },
  {
    name: "account-perks",
    path: "/account/perks",
    meta: perkspJqzqUHPqRMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/perks.vue").then(m => m.default || m)
  },
  {
    name: "account-persona",
    path: "/account/persona",
    meta: personaER107y7pMTMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/persona.vue").then(m => m.default || m),
    children: [
  {
    name: "account-persona-address",
    path: "address",
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/persona/address.vue").then(m => m.default || m)
  },
  {
    name: "account-persona-certifications",
    path: "certifications",
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/persona/certifications.vue").then(m => m.default || m)
  },
  {
    name: "account-persona-profile",
    path: "profile",
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/persona/profile.vue").then(m => m.default || m)
  },
  {
    name: "account-persona-social",
    path: "social",
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/persona/social.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "account-referral",
    path: "/account/referral",
    meta: referral4liicupS3TMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/referral.vue").then(m => m.default || m)
  },
  {
    name: "account-security",
    path: "/account/security",
    meta: securityLZXcbfu7XrMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/security.vue").then(m => m.default || m)
  },
  {
    name: "auth-invite-uuid",
    path: "/auth/invite/:uuid()",
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/auth/invite/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "auth-invite-b2b-token",
    path: "/auth/invite/b2b/:token()",
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/auth/invite/b2b/[token].vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add",
    path: "/contractors/:workspaceId()/accounts/add",
    meta: addHUKVlDreEiMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add.vue").then(m => m.default || m),
    children: [
  {
    name: "contractors-workspaceId-accounts-add-arbitrum-wallet",
    path: "arbitrum-wallet",
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/arbitrum-wallet.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-binance",
    path: "binance",
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/binance.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-coinbase",
    path: "coinbase",
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/coinbase.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-domestic-usd",
    path: "domestic-usd",
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/domestic-usd.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-ethereum-wallet",
    path: "ethereum-wallet",
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/ethereum-wallet.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-forex",
    path: "forex",
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/forex.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-international-usd-manual",
    path: "international-usd-manual",
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/international-usd-manual.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-international-usd",
    path: "international-usd",
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/international-usd.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-kraken",
    path: "kraken",
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/kraken.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "contractors-workspaceId-accounts",
    path: "/contractors/:workspaceId()/accounts",
    meta: indexN0esn6QQb9Meta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/index.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-dashboard",
    path: "/contractors/:workspaceId()/dashboard",
    meta: dashboardAABb68PYBEMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-documents",
    path: "/contractors/:workspaceId()/documents",
    meta: documents5adKSMhaCfMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/documents.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-history-completed",
    path: "/contractors/:workspaceId()/history/completed",
    meta: completedFpdDf6aYioMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/history/completed.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-history-pending",
    path: "/contractors/:workspaceId()/history/pending",
    meta: pending7BIrFMWSwMMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/history/pending.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-invoices-flat-id",
    path: "/contractors/:workspaceId()/invoices/flat/:id()",
    meta: _91id_937VP7lhubc6Meta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/invoices/flat/[id].vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-invoices-flat",
    path: "/contractors/:workspaceId()/invoices/flat",
    meta: indexvbwNk6jw47Meta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/invoices/flat/index.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-invoices",
    path: "/contractors/:workspaceId()/invoices",
    meta: indexieMuc7nhMTMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-invoices-time-id",
    path: "/contractors/:workspaceId()/invoices/time/:id()",
    meta: _91id_93vA6MlztUxSMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/invoices/time/[id].vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-invoices-time",
    path: "/contractors/:workspaceId()/invoices/time",
    meta: indexG6ORpR0WsRMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/invoices/time/index.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-tasks",
    path: "/contractors/:workspaceId()/tasks",
    meta: tasksmtm2dRVUcRMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/tasks.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-withdraw",
    path: "/contractors/:workspaceId()/withdraw",
    meta: withdrawgC3CrP8UWCMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw.vue").then(m => m.default || m),
    children: [
  {
    name: "contractors-workspaceId-withdraw-accountId-crypto",
    path: ":accountId()/crypto",
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw/[accountId]/crypto.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-withdraw-accountId-fiat",
    path: ":accountId()/fiat",
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw/[accountId]/fiat.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-withdraw-nanoId-direct",
    path: ":nanoId()/direct",
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw/[nanoId]/direct.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "employees-workspaceId-benefits",
    path: "/employees/:workspaceId()/benefits",
    meta: benefitsxjN7I2AHRTMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/benefits.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-dashboard",
    path: "/employees/:workspaceId()/dashboard",
    meta: dashboardnJgqAeWwXeMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-documents",
    path: "/employees/:workspaceId()/documents",
    meta: documentsOMp9mptbrIMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/documents.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-pay-slips",
    path: "/employees/:workspaceId()/pay-slips",
    meta: pay_45slipsGseuTkRGryMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/pay-slips.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-reimbursements",
    path: "/employees/:workspaceId()/reimbursements",
    meta: reimbursementsyhXaQ8ICjfMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/reimbursements.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-tasks",
    path: "/employees/:workspaceId()/tasks",
    meta: tasksS4VsYAtaibMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/tasks.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-withdraw",
    path: "/employees/:workspaceId()/withdraw",
    meta: withdraw0f5WESbs29Meta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/withdraw.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexq8ipwTDgX7Meta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invites-nanoid",
    path: "/invites/:nanoid()",
    meta: _91nanoid_93FAAX2PTVj6Meta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/invites/[nanoid].vue").then(m => m.default || m)
  },
  {
    name: "jwt",
    path: "/jwt",
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/jwt.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-activity",
    path: "/organizations/:workspaceId()/activity",
    meta: activity19HOxGAs6GMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/activity.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-administrators",
    path: "/organizations/:workspaceId()/administrators",
    meta: administratorssfSLeX6bXRMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/administrators.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-dashboard",
    path: "/organizations/:workspaceId()/dashboard",
    meta: dashboardBfqx3cSjzkMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-history-completed",
    path: "/organizations/:workspaceId()/history/completed",
    meta: completedgBVgeNzKC6Meta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/history/completed.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-history-pending",
    path: "/organizations/:workspaceId()/history/pending",
    meta: pendingkinbiIBTLYMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/history/pending.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-invites",
    path: "/organizations/:workspaceId()/invites",
    meta: inviteszofoWiXFmAMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites.vue").then(m => m.default || m),
    children: [
  {
    name: "organizations-workspaceId-invites-direct",
    path: "direct",
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites/direct.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-invites-warm",
    path: "warm",
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites/warm.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "organizations-workspaceId-subscription",
    path: "/organizations/:workspaceId()/subscription",
    meta: subscriptionLMyGIDgIJuMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/subscription.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-tasks",
    path: "/organizations/:workspaceId()/tasks",
    meta: tasks95Uv3aOua2Meta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/tasks.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-teams-teamId",
    path: "/organizations/:workspaceId()/teams/:teamId()",
    meta: _91teamId_93LzICO9YmGqMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/teams/[teamId].vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-teams",
    path: "/organizations/:workspaceId()/teams",
    meta: index5ZeVypeTlWMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/teams/index.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-treasury-balance-transfer",
    path: "/organizations/:workspaceId()/treasury/balance-transfer",
    meta: balance_45transferLBDFGtJVzNMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/balance-transfer.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-treasury-deposit",
    path: "/organizations/:workspaceId()/treasury/deposit",
    meta: depositlCeeBZg4LJMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit.vue").then(m => m.default || m),
    children: [
  {
    name: "organizations-workspaceId-treasury-deposit-blockchain-address",
    path: "blockchain-address",
    meta: blockchain_45addressODsD19CSKuMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit/blockchain-address.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-treasury-deposit-wire",
    path: "wire",
    meta: wire0fl7OfUTjLMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit/wire.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "playground",
    path: "/playground",
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/playground.vue").then(m => m.default || m)
  },
  {
    name: "receipts-id",
    path: "/receipts/:id(.*)*",
    meta: _91_46_46_46id_93HH00REp7NfMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/receipts/[...id].vue").then(m => m.default || m)
  },
  {
    name: "receipts-external",
    path: "/receipts/external",
    meta: externalpgA4ZUtqXhMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/receipts/external.vue").then(m => m.default || m)
  },
  {
    name: "sign-in-2fa",
    path: "/sign-in/2fa",
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/2fa.vue").then(m => m.default || m)
  },
  {
    name: "sign-in-code",
    path: "/sign-in/code",
    meta: codebqWW7EFOlfMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/code.vue").then(m => m.default || m)
  },
  {
    name: "sign-in",
    path: "/sign-in",
    meta: index4hWSLz3uLBMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/index.vue").then(m => m.default || m)
  },
  {
    name: "sign-out",
    path: "/sign-out",
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-out.vue").then(m => m.default || m)
  },
  {
    name: "sign-up-code",
    path: "/sign-up/code",
    meta: code0hPzc2VAwXMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/code.vue").then(m => m.default || m)
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: indexbWvDOlI3H4Meta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/index.vue").then(m => m.default || m)
  },
  {
    name: "sign-up-waiting",
    path: "/sign-up/waiting",
    meta: waitingx1vf00Kra7Meta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/waiting.vue").then(m => m.default || m)
  },
  {
    name: "sign",
    path: "/sign",
    meta: signbABMp31caIMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-dashboard",
    path: "/teams/:workspaceId()/dashboard",
    meta: dashboardQvhq9ae6A1Meta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-deposit",
    path: "/teams/:workspaceId()/deposit",
    meta: depositSbkq6VASvWMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit.vue").then(m => m.default || m),
    children: [
  {
    name: "teams-workspaceId-deposit-blockchain-address",
    path: "blockchain-address",
    meta: blockchain_45address2G5q7U571JMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit/blockchain-address.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-deposit-wire",
    path: "wire",
    meta: wireAIlIPX4M4KMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit/wire.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "teams-workspaceId-documents",
    path: "/teams/:workspaceId()/documents",
    meta: documents7xgB5ZdKSYMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/documents.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-history",
    path: "/teams/:workspaceId()/history",
    meta: historyNj4P1dv5K8Meta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/history.vue").then(m => m.default || m),
    children: [
  {
    name: "teams-workspaceId-history-completed",
    path: "completed",
    meta: completeddnsMPX92dMMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/history/completed.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-history-pending",
    path: "pending",
    meta: pendingxstgxfLmlrMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/history/pending.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "teams-workspaceId-invites",
    path: "/teams/:workspaceId()/invites",
    meta: invitesiYdgLpgNcLMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites.vue").then(m => m.default || m),
    children: [
  {
    name: "teams-workspaceId-invites-batch",
    path: "batch",
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/batch.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-invites-direct",
    path: "direct",
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/direct.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-invites-warm",
    path: "warm",
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/warm.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "teams-workspaceId-invoices",
    path: "/teams/:workspaceId()/invoices",
    meta: invoices9bIoeFRxjNMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invoices.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-members-userId",
    path: "/teams/:workspaceId()/members/:userId()",
    meta: _91userId_937py7a50eilMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId].vue").then(m => m.default || m),
    children: [
  {
    name: "teams-workspaceId-members-userId-benefits",
    path: "benefits",
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId]/benefits.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-members-userId-summary",
    path: "summary",
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId]/summary.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "teams-workspaceId-members",
    path: "/teams/:workspaceId()/members",
    meta: indexdKP2JDDXb4Meta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/index.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay-batch-csv",
    path: "/teams/:workspaceId()/pay/batch/csv",
    meta: csvXNBCy7sfQeMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/batch/csv.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay-batch",
    path: "/teams/:workspaceId()/pay/batch",
    meta: indexiGVqodpOSDMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/batch/index.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay",
    path: "/teams/:workspaceId()/pay",
    meta: indexkaXaHrG7tEMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/index.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay-individual-userId",
    path: "/teams/:workspaceId()/pay/individual/:userId()",
    meta: _91userId_93nMgf62tODyMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId].vue").then(m => m.default || m),
    children: [
  {
    name: "teams-workspaceId-pay-individual-userId-direct",
    path: "direct",
    meta: directWDruCRFYIsMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/direct.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay-individual-userId-hourly",
    path: "hourly",
    meta: hourlyTkMbDwb6tqMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/hourly.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay-individual-userId-instant",
    path: "instant",
    meta: instantN4FWYowsnUMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/instant.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay-individual-userId-milestone",
    path: "milestone",
    meta: milestoneUskVuxbugMMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/milestone.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay-individual-userId-once",
    path: "once",
    meta: oncetOxqPCPMG1Meta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/once.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay-individual-userId-recurring",
    path: "recurring",
    meta: recurringW5aAuRJtSKMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/recurring.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "teams-workspaceId-payroll",
    path: "/teams/:workspaceId()/payroll",
    meta: payrolltwK0LlTdAjMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-settings",
    path: "/teams/:workspaceId()/settings",
    meta: settingsyyqlUNYZTuMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/settings.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-tasks",
    path: "/teams/:workspaceId()/tasks",
    meta: tasks7asV6fLXcnMeta || {},
    component: () => import("/data/runners/four/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/tasks.vue").then(m => m.default || m)
  }
]